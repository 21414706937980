
export function modCalculation(value){
    return Math.floor((value - 10) / 2)
}


export function showSign(value){
    if(typeof value !== "number"){
        value = parseInt(value)
    }

    if(value >= 0){
        return "+" + value
    }
    return value
}


export function shortStatName(value){
    switch (value) {
        case "stats.dex":
            return "Dex"

        case "stats.str":
            return "Str"

        case "stats.con":
            return "Con"

        case "stats.int":
            return "Int"

        case "stats.wis":
            return "Wis"

        case "stats.cha":
            return "Cha"
        default:
            return value
    }
}