import {useContext} from "react";
import {getValueOfSheet, SheetContext} from "./SheetContext";


export default function FirebaseTextarea (props) {


    let {sheet, update} = useContext(SheetContext)

    let value = getValueOfSheet(sheet, props.firebase) ?? ""


    return <textarea
        className="form-control"
        style={{height: (props.cols ?? 5)* 25}}
        value={value}
        onChange={(e) =>  update( props.firebase, e.target.value)}
    />

}