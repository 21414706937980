import {useContext} from "react";
import {getValueOfSheet, SheetContext} from "./SheetContext";
import FirebaseTextarea from "./FirebaseTextarea";


export default function FirebaseInput (props) {


    let {sheet, update} = useContext(SheetContext)

    let value = getValueOfSheet(sheet, props.firebase) ?? ""
    let type = props.type ?? "text"


    if(type === "textarea"){
        return <FirebaseTextarea {...props} />
    }

    return <input
        type={type}
        className="form-control"
        value={value}
        onChange={(e) =>  update( props.firebase, e.target.value)}
    />

}