import {getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged} from "firebase/auth";
import {useEffect, useState} from "react";
import App from "./App";
import {initializeApp} from "firebase/app";
import {getDatabase, push, onValue, ref, off} from "firebase/database";
import {signOut} from "@firebase/auth";

export default function Login() {
    const firebaseConfig = {
        apiKey: "AIzaSyAgvGrXyzV2-hq4JoxfRYqjbro7jJuMRsM",
        authDomain: "dnd-sheet-b19cf.firebaseapp.com",
        databaseURL: "https://dnd-sheet-b19cf-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "dnd-sheet-b19cf",
        storageBucket: "dnd-sheet-b19cf.appspot.com",
        messagingSenderId: "755265433633",
        appId: "1:755265433633:web:38375ae0bec802fbff3360"
    };


    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();


   function loginToGoogle(){
       signInWithPopup(auth, provider)
           .catch((error) => {
           // Handle Errors here.
           const errorCode = error.code;
           const errorMessage = error.message;
           // The email of the user's account used.
           const email = error.customData.email;
           // The AuthCredential type that was used.
           const credential = GoogleAuthProvider.credentialFromError(error);
           console.log(errorCode, errorMessage, email, credential)
       });
   }




    let [loggedInUser, setLogin] = useState(null)
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLogin(user.uid)
            } else {
                setLogin(null)
            }
        });

    }, [auth])



    let [selectedCharacter, setSelectedCharacter] = useState(null)
    let [characters, setCharacters] = useState([])

    useEffect(() => {
        let listener = function() {
            setSelectedCharacter(document.location.hash.substring(1))
        }
        window.addEventListener('hashchange', listener, false);

        if(document.location.hash){

            setSelectedCharacter(document.location.hash.substring(1))
        }

        return () => {
            window.removeEventListener('hashchange', listener);
        }

    }, [])

    useEffect(() => {

        if(loggedInUser) {

            const charactersRef = ref(database, "sheets/" + loggedInUser);
            onValue(charactersRef, (charactersSnapshot) => {

                let newChars = [];
                const characterData = charactersSnapshot.val();
                for(let char in characterData ){
                    if(characterData.archived) {
                        continue
                    }

                    newChars.push({
                        key: "#" + char,
                        name: characterData[char]['name'] ?? "No name: " + char
                    })
                }


                setCharacters(newChars)
            }, (e) => {
                console.error(e)
            })

            return () => {
                off(charactersRef)
            }

        } else {
            setCharacters([])
        }

    }, [loggedInUser, database])


    function createCharacter () {
        let characterName = prompt("What will the name be?");
        if(characterName) {
            const charactersRef = ref(database, "sheets/" + loggedInUser);
            push(charactersRef, {
                name: characterName,
            })
        }
    }

    if(loggedInUser){
        return <div>

            <nav className="py-2 fixed-top  bg-light border-bottom">
                <div className="container d-flex flex-wrap">
                    <ul className="nav me-auto">
                        <li className="nav-item dropdown">
                            <button className="nav-link btn btn-link dropdown-toggle"  data-bs-toggle="dropdown"
                               aria-expanded="false">
                                Characters
                            </button>
                            <ul className="dropdown-menu">
                                {characters.map(({key, name}) => <li key={key}><a className="dropdown-item" href={key}>{name}</a></li>)}
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li><button className="dropdown-item"  onClick={() => createCharacter()}>Create</button></li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="nav">
                        <li className="nav-item"><button onClick={() => signOut(auth)} className="nav-link link-dark px-2">Logout</button></li>
                    </ul>
                </div>
            </nav>
            <div style={{marginTop: '57px'}}>
                { selectedCharacter ?
                    <App database={database} uid={loggedInUser} character={selectedCharacter} />
                    : <div> Select a character</div> }
            </div>



        </div>

    } else {
        return <div>
            <div className="px-4 py-5 my-5 text-center">
                    <h1 className="display-5 fw-bold">D&D sheet</h1>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque imperdiet finibus. Cras nec elit vel lacus rhoncus volutpat. Sed et ante ullamcorper, vulputate odio rhoncus, varius nibh. Vivamus sem diam, dignissim et urna id, placerat dictum nisl. Sed posuere, turpis id cursus egestas, arcu nibh convallis diam, eu congue tortor metus eu augue. Phasellus et ullamcorper nulla. Sed tincidunt, lorem non viverra accumsan, ligula urna sodales neque, ac dapibus orci nisl non nisl. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <button type="button" className="btn btn-primary btn-lg px-4 gap-3" onClick={() => loginToGoogle()} >Login with Google</button>
                        </div>
                    </div>
            </div>



        </div>
    }
}