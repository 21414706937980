import {useContext} from "react";
import {SheetContext, getValueOfSheet, getNumberValueOfSheet} from "./SheetContext";
import {modCalculation, showSign} from "./Utils";

export default function RelativeModifier(props) {
    let {sheet, update} = useContext(SheetContext)


    let editMode = getValueOfSheet(sheet, "edit_mode")

    let tempValue = getNumberValueOfSheet(sheet, props.firebase + "_tmp")

    let baseMod = props.base ? modCalculation(getNumberValueOfSheet(sheet, props.base)) : getNumberValueOfSheet(sheet, props.firebase);

    let editField = null

    if (editMode) {
        editField = <div className="edit-container">
            {!props.base ?
                <>Base:
                    <input className="form-control" value={baseMod ?? ""}
                           onChange={(e) => update(props.firebase, e.target.value)}/></>
                : null
            }

            Temp mods:
            <input className="form-control" value={tempValue ?? ""}
                   onChange={(e) => update(props.firebase + "_tmp", e.target.value)}/>

        </div>
    }

    let mod = baseMod + tempValue ?? 0
    var tempValueElement = null;

    if (parseInt(tempValue ?? 0) !== 0) {
        tempValueElement = <span>({tempValue} tmp)</span>
    }


    return <div>
        <b>
            {showSign(mod)} {props.label} {tempValueElement}
        </b>
        {editField}
    </div>;


}
