import {useContext} from "react";
import {getValueOfSheet, SheetContext} from "./SheetContext";

export default function FirebaseCheckbox (props) {


    let {sheet, update} = useContext(SheetContext)

    let value = getValueOfSheet(sheet, props.firebase) ?? ""


    return <input
        type="checkbox"
        defaultChecked={value}
        onChange={(e) =>  update( props.firebase, e.target.checked)}
    />

}