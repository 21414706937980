import {useContext} from "react";
import {getValueOfSheet, SheetContext} from "./SheetContext";


export default function FirebaseShow (props) {


    let {sheet} = useContext(SheetContext)

    let value = getValueOfSheet(sheet, props.firebase) ?? props.fallback ?? ""

    let parts = value.split(/[\n\r]+/).map((i) => <div>{i}</div>)

    return <span>{parts}</span>

}