import {useContext} from "react";
import {SheetContext, getValueOfSheet} from "./SheetContext";
import FirebaseInput from "./FirebaseInput";
import FirebaseTextarea from "./FirebaseTextarea";
import {showSign} from "./Utils";

export default function WeaponsInput(props) {
    let {sheet, update} = useContext(SheetContext)

    let editMode = getValueOfSheet(sheet, "edit_mode")

    let updateValue = getValueOfSheet(sheet, props.firebase) ?? [];


    function addItem() {
        let index = updateValue.length;
        update(props.firebase + "." + index, {
            "name": "New weapon",
            "bonus": "0",
            "damage": "",
            "type": "",
        })
    }

    function removeIndex(deleteIndex){
        if(window.confirm("Are you sure?")){
            let newList = [];
            for(let key in updateValue){
                if(parseInt(key) !== deleteIndex){
                    newList.push(updateValue[key])
                }
            }
            update(props.firebase, newList)
        }
    }

    if (editMode) {

        return (
            <div>
                <h2>{props.label}</h2>
                {updateValue.map((weapon, index) => {

                    return <div className="row edit-container" key={index}>
                        <div className="col-12">
                            <button onClick={() => removeIndex(index) }>Delete</button>
                        </div>
                        <div className="col-12 col-md-6">
                            Name:
                            <FirebaseInput firebase={`${props.firebase}.${index}.name`}/>
                        </div>
                        <div className="col-12 col-md-6">
                            Attack bonus:
                            <FirebaseInput firebase={`${props.firebase}.${index}.bonus`} type="number"/>
                        </div>
                        <div className="col-12 col-md-6">
                            Damage type:
                            <FirebaseInput firebase={`${props.firebase}.${index}.type`} type="text"/>
                        </div>

                        <div className="col-12 col-md-6">
                            Amount of damage:
                            <FirebaseInput firebase={`${props.firebase}.${index}.damage`} type="text"/>
                        </div>
                        <div className="col-12">
                            Extra notes:
                            <FirebaseTextarea firebase={`${props.firebase}.${index}.notes`}/>
                        </div>
                    </div>
                })}
                <button onClick={() => addItem()}>Add</button>

            </div>
        );

    }

    return (
        <div>
            <h2>{props.label}</h2>
            {updateValue.map((weapon, index) =>
                <div className="row mb-2 g-2" key={index}>
                    <div className="col-5 ">
                        <div className="insetfield">
                            {weapon.name}
                        </div>
                    </div>
                    <div className="col-2 text-center">
                        <div className="insetfield">
                            {showSign(weapon.bonus)}
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="insetfield">
                            {weapon.damage} {weapon.type}
                        </div>
                    </div>
                    {weapon.notes ? <p>{weapon.notes}</p> : null}
                </div>
            )}

        </div>
    );


}

