import FirebaseInput from "./FirebaseInput";
import FirebaseCheckbox from "./FirebaseCheckbox";
import {useState, useContext} from "react";
import FirebaseShow from "./FirebaseShow";
import CheckmarksElement from "./CheckmarksElement";
import {getValueOfSheet, SheetContext} from "./SheetContext";
import FirebaseTextarea from "./FirebaseTextarea";

import {ReactComponent as ShowIcon} from 'bootstrap-icons/icons/eye-fill.svg';

export default function SpellSlots(props) {
    let spelllevel = props.spellLevel

    let [editMode, setEditMode] = useState(false)
    let [showMode, setShowMode] = useState(false)

    let count = 1
    let {sheet} = useContext(SheetContext)

    let spells = getValueOfSheet(sheet, `spellevels.${spelllevel}.spells`)
    for(let i in spells){
        if(spells[i].name && spells[i].name.trim()  !== ""){
            count = parseInt(i) + (editMode ? 2 : 1);
        }
    }




    return    <div className="col-12 col-lg-4">
        <div className="row">
            <div className="col-2"><h1>{spelllevel}</h1></div>
            <div className="col-3">

                <FirebaseInput firebase={`spells.${spelllevel}.currentslots`}/>

            </div>
            <div className="col-2">/</div>

            <div className="col-3">
                {editMode ? <FirebaseInput firebase={`spells.${spelllevel}.totalslots`}/>
                    : <FirebaseShow firebase={`spells.${spelllevel}.totalslots`}/> }
            </div>
            <div className="col-2">
                <button className="btn btn-primary" onClick={() => setEditMode(!editMode)}>{editMode ? "Stop edit" : "Edit"}</button>
            </div>
            {[...Array(count).keys()].map((slot) =>
                <div className="row"
                     key={slot}>
                    <div className="col-2">
                        {editMode ? <FirebaseCheckbox firebase={`spellevels.${spelllevel}.spells.${slot}.prepared`}/>
                            : <CheckmarksElement firebase={`spellevels.${spelllevel}.spells.${slot}.prepared`} /> }

                    </div>
                    <div className="col-9">
                        {editMode ? <FirebaseInput firebase={`spellevels.${spelllevel}.spells.${slot}.name`}/>
                            : <FirebaseShow firebase={`spellevels.${spelllevel}.spells.${slot}.name`} fallback="-"/> }

                    </div>

                    <div className="col-1">
                        { editMode ? null : <button className="btn btn-outline-info mb-1" onClick={()=> showMode === slot ?  setShowMode(-1) : setShowMode(slot)}><ShowIcon /></button>}
                    </div>

                    { editMode ? <FirebaseTextarea cols={6} firebase={`spellevels.${spelllevel}.spells.${slot}.description`}/> :
                        (showMode === slot? <p className="value-container"><FirebaseShow firebase={`spellevels.${spelllevel}.spells.${slot}.description`} fallback="-"/></p> : null)}


                </div>)}

        </div>
    </div>

}