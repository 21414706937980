import {useContext} from "react";
import {getValueOfSheet, SheetContext} from "./SheetContext";

import {ReactComponent as CheckedIcon} from 'bootstrap-icons/icons/check-square-fill.svg';
import {ReactComponent as UncheckedIcon} from 'bootstrap-icons/icons/square.svg';

export default function CheckmarksElement (props) {


    let updateValue = props.count

    let {sheet} = useContext(SheetContext)
    if(props.firebase) {

        updateValue = getValueOfSheet(sheet, props.firebase) ?? ""
    }

    let checks = [];
    if(updateValue > 0){
        for (let i = 0; i < updateValue; i++) {
            checks.push(<CheckedIcon key={i}/>)
        }
    } else {
        checks.push(<UncheckedIcon key="no"/>)
    }


    return checks

}