import {useContext, useState} from "react";
import {SheetContext, getValueOfSheet} from "./SheetContext";
import RelativeModifier from "./RelativeModifier";

export default function HealthWidget(props) {
    let {sheet, update} = useContext(SheetContext)

    let firebaseKey = props.firebase
    let maxFirebaseKey = props.maxKey

    let editMode = getValueOfSheet(sheet, "edit_mode")
    let currentHp = getValueOfSheet(sheet, firebaseKey)
    let maxHealth = getValueOfSheet(sheet, maxFirebaseKey);

    let [modifyHp, setModifyHp] = useState('')

    let onKeyDown = (e) => {
        if(e.key === 'Enter'){
            if(modifyHp.toLowerCase() === 'max' || modifyHp.toLowerCase() === 'm'){
                update(firebaseKey, maxHealth)
                setModifyHp('')
            } else if(modifyHp.match(/^[-+][0-9]+$/)){

                update(firebaseKey, parseInt(e.target.value) + parseInt(currentHp ?? 0))

                setModifyHp('')
            } else if(modifyHp.match(/^[0-9]+$/)){

                update(firebaseKey, parseInt(e.target.value))

                setModifyHp('')
            } else {
                alert("invalid value")
            }

        }

    }

    if(editMode){
        return <div>
            <RelativeModifier label="Max health" firebase={maxFirebaseKey}/>

            <RelativeModifier label="Current health" firebase={firebaseKey}/>

        </div>;
    } else {
        let percentage = (currentHp / maxHealth) * 100

        return <div>
            {currentHp} / {maxHealth}
            <div className="progress">
                <div className="progress-bar bg-danger" role="progressbar" aria-label="Example with label" style={{width: `${percentage}%`}}
                     aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
                    {percentage >= 6 ? Math.floor(percentage) + "%" : null }
                </div>
                &nbsp;{percentage < 6 ? Math.floor(percentage) + "%" : null }
            </div>
            <div className="mt-1">

                {!editMode ? <input className="form-control" value={modifyHp} onKeyDown={onKeyDown} onChange={(e) => setModifyHp(e.target.value)}/> : null }
            </div>

        </div>;

    }




}
