import {useContext} from "react";
import {SheetContext, getValueOfSheet} from "./SheetContext";
import {shortStatName, showSign} from "./Utils";
import CheckmarksElement from "./CheckmarksElement";

export default function SkillInput(props) {
    let {sheet, update} = useContext(SheetContext)


    let editMode = getValueOfSheet(sheet, "edit_mode")

    let proficiency = getValueOfSheet(sheet, props.profKey)
    let updateValue = getValueOfSheet(sheet, props.firebase)
    let tempValue = getValueOfSheet(sheet, props.firebase + "_tmp")
    let baseMod = (getValueOfSheet(sheet, props.base) - 10) / 2


    let editField = null

    if (editMode) {
        editField = <div className="edit-container">
            Proficiency:
            <select className="form-control  form-select-sm" value={updateValue ?? "0"}
                    onChange={(e) => update(props.firebase, e.target.value)}>
                <option value="0">No proficiency</option>
                <option value="1">1x proficiency</option>
                <option value="2">2x proficiency</option>
                <option value="3">3x proficiency</option>
                <option value="4">4x proficiency</option>

            </select>
            Temp mods:
            <input className="form-control" value={tempValue ?? ""}
                   onChange={(e) => update(props.firebase + "_tmp", e.target.value)}/>

        </div>
    }

    let mod = parseInt(baseMod) + parseInt((updateValue ?? 0) * proficiency) + parseInt(tempValue ?? 0)
    var tempValueElement = null;

    if (parseInt(tempValue ?? 0) !== 0) {
        tempValueElement = <span>({tempValue} tmp)</span>
    }


    return <div>
        <CheckmarksElement count={updateValue} />
        <b>
            {showSign(mod)} {props.label} {tempValueElement} ({shortStatName(props.base)})
        </b>
        {editField}
    </div>;


}
