import {ref, onValue, set, push, serverTimestamp, off} from "firebase/database";
import {useEffect, useState} from "react";
import Input from "./Input";
import {SheetContext} from "./SheetContext";
import BaseStatInput from "./BaseStatInput";
import SkillInput from "./SkillInput";
import ButtonInput from "./ButtonInput";
import RelativeModifier from "./RelativeModifier";
import WeaponsInput from "./WeaponsInput";
import FirebaseTextarea from "./FirebaseTextarea";
import SpellSlots from "./SpellSlots";
import HealthWidget from "./HealthWidget";


function App(props) {
    let database = props.database
    let sheetLocation = `sheets/${props.uid}/${props.character}/`


    let [sheet, setSheet] = useState({
        sheet: {
            name: "test",
        },
        update: () => {
            console.log("Not there yet")
        }
    })

    useEffect(() => {

        const characterSheetRef = ref(database, sheetLocation);
        onValue(characterSheetRef, (snapshot) => {
            const data = snapshot.val();
            setSheet({
                sheet: data,
                update: (key, value) => {
                    let fullkey = sheetLocation + key.split(".").join("/");
                    set(ref(database, fullkey), value).catch((e) => {
                        console.error(e)

                    })
                    push(ref(database, sheetLocation + '/changelog'), {
                        key: key,
                        newValue: value,
                        time: serverTimestamp()
                    }).catch((e) => {
                        console.error(e)

                    })
                }
            });
        });

        return () => {
            off(characterSheetRef)
        }

    }, [sheetLocation, database])

    let tabs = {};


    tabs['Main'] = <>
        <div className="row">
            <div className="col-12 "> <Input label="Name" firebase="name"/></div>
            <div className="col-6 col-md-4"><Input label="Class & Level" firebase="class"/></div>
            <div className="col-6 col-md-4"><Input label="Background" firebase="background"/></div>
            <div className="col-6 col-md-4"><Input label="Player name" firebase="playername"/></div>
            <div className="col-6 col-md-4"><Input label="Race" firebase="race"/></div>
            <div className="col-6 col-md-4"><Input label="Alignment" firebase="alignment"/></div>
            <div className="col-6 col-md-4"><Input label="Experience points"
                                                   firebase="experience_points"/></div>

            <div className="col-12 value-container col-md-4">
                <RelativeModifier label="Armor class" firebase="ac"/>
            </div>
            <div className="col-12 value-container col-md-4">
                <RelativeModifier label="Initiative" firebase="initiative" base="stats.dex"/>
            </div>
            <div className="col-12 value-container col-md-4">
                <RelativeModifier label="Speed" firebase="speed"/>
            </div>

        </div>

        <div className="value-container">
            <HealthWidget firebase="health" maxKey="max_health" />
        </div>


        <div className="row">
            <div className="col-12 col-md-6 value-container">
                <Input label="Total Hit dice" firebase="hit_dice"/>
                <Input label="Hit dice left" firebase="hit_dice_current"/>
            </div>
            <div className="col-12 col-md-6 value-container">
                <h3>Death saves</h3>
                <Input label="Success" firebase="death_save_success"/>
                <Input label="Fail" firebase="death_save_failure"/>
            </div>
        </div>

        <WeaponsInput label="Weapons" firebase="weapons"/>

        <div className="row">
            <div className="col-2">
                <Input label="CP" firebase="money.cp" fallback="0"/>
            </div>
            <div className="col-2">
                <Input label="SP" firebase="money.sp" fallback="0"/>
            </div>
            <div className="col-2">
                <Input label="EP" firebase="money.ep" fallback="0"/>
            </div>
            <div className="col-2">
                <Input label="GP" firebase="money.gp" fallback="0"/>
            </div>
            <div className="col-2">
                <Input label="PP" firebase="money.pp" fallback="0"/>
            </div>
            <div className="col-12">
                <FirebaseTextarea cols={10} firebase="equipment"/>
            </div>

        </div>

    </>

    tabs['Stats'] =  <div className="row">
        <div className="col-4">
            <BaseStatInput label="Strength" firebase="stats.str"/>
            <BaseStatInput label="Dexterity" firebase="stats.dex"/>
            <BaseStatInput label="Constitution" firebase="stats.con"/>
            <BaseStatInput label="Intelligence" firebase="stats.int"/>
            <BaseStatInput label="Wisdom" firebase="stats.wis"/>
            <BaseStatInput label="Charisma" firebase="stats.cha"/>
        </div>
        <div className="col-8">
            <div className="value-container">
                <Input label="Inspiration" firebase="inspiration"/>
            </div>
            <div className="value-container">
                <Input label="Proficiency bonus" firebase="proficiency"/>
            </div>
            <div className="value-container">
                <h2>Saves</h2>
                <SkillInput label="Dexterity Save" profKey="proficiency" firebase="stats.dex_save"
                            base="mod(stats.dex)"/>
                <SkillInput label="Constitution Save" profKey="proficiency"
                            firebase="stats.con_save"
                            base="stats.con"/>
                <SkillInput label="Intelligence Save" profKey="proficiency"
                            firebase="stats.int_save"
                            base="stats.int"/>
                <SkillInput label="Wisdom Save" profKey="proficiency" firebase="stats.wis_save"
                            base="stats.wis"/>
                <SkillInput label="Charisma Save" profKey="proficiency" firebase="stats.cha_save"
                            base="stats.cha"/>
            </div>

            <div className="value-container">
                <h2>Skills </h2>
                <SkillInput label="Acrobatics" profKey="proficiency" firebase="skill.Acrobatics"
                            base="stats.dex"/>
                <SkillInput label="Animal Handling" profKey="proficiency"
                            firebase="skill.animal_handling" base="stats.wis"/>
                <SkillInput label="Arcana" profKey="proficiency" firebase="skill.arcana"
                            base="stats.int"/>
                <SkillInput label="Athletics" profKey="proficiency" firebase="skill.athletics"
                            base="stats.str"/>
                <SkillInput label="Deception" profKey="proficiency" firebase="skill.deception"
                            base="stats.cha"/>
                <SkillInput label="History" profKey="proficiency" firebase="skill.history"
                            base="stats.int"/>
                <SkillInput label="Insight" profKey="proficiency" firebase="skill.insight"
                            base="stats.wis"/>
                <SkillInput label="Intimidation" profKey="proficiency" firebase="skill.Intimidation"
                            base="stats.cha"/>
                <SkillInput label="Investigation" profKey="proficiency"
                            firebase="skill.Investigation"
                            base="stats.int"/>
                <SkillInput label="Medicine" profKey="proficiency" firebase="skill.medicine"
                            base="stats.wis"/>
                <SkillInput label="Nature" profKey="proficiency" firebase="skill.nature"
                            base="stats.int"/>
                <SkillInput label="Perception" profKey="proficiency" firebase="skill.Perception"
                            base="stats.wis"/>
                <SkillInput label="Performance" profKey="proficiency" firebase="skill.Performance"
                            base="stats.cha"/>
                <SkillInput label="Persuasion" profKey="proficiency" firebase="skill.Persuasion"
                            base="stats.cha"/>
                <SkillInput label="Religion" profKey="proficiency" firebase="skill.religion"
                            base="stats.int"/>
                <SkillInput label="Sleight of Hand" profKey="proficiency"
                            firebase="skill.sleight_of_hand" base="stats.dex"/>
                <SkillInput label="Stealth" profKey="proficiency" firebase="skill.stealth"
                            base="stats.dex"/>
                <SkillInput label="Survival" profKey="proficiency" firebase="skill.survival"
                            base="stats.wis"/>
            </div>

        </div>
    </div>


    tabs['Personality'] = <> <div className="value-container">
        <Input label="Personality traits" firebase="personality_traits" type="textarea"/>
    </div>

        <div className="value-container">
            <Input label="Ideals" firebase="ideals" type="textarea"/>
        </div>

        <div className="value-container">
            <Input label="Bonds" firebase="bonds" type="textarea"/>
        </div>

        <div className="value-container">
            <Input label="Flaws" firebase="flaws" type="textarea"/>
        </div>

        <div className="value-container">
            <Input label="Character appearance" firebase="appearance" type="textarea"/>
        </div>

        <div className="value-container">
            <Input label="Backstory" firebase="backstory" type="textarea"/>
        </div>

        <div className="value-container">
            <Input label="Allies & Organisations" firebase="allies_organisations" type="textarea"/>
        </div>

        <div className="value-container">
            <Input label="Treasure" firebase="treasure" type="textarea"/>
        </div>
    </>

    tabs['Spells'] = <div className="row">
        {[...Array(9).keys()].map((spelllevel) =>
            <SpellSlots spellLevel={spelllevel} key={spelllevel} />
        )}
    </div>


    if(sheet.sheet.changelog) {
        let dateFormat = { day: 'numeric',month: 'long', year: 'numeric', hour:'numeric', minute: 'numeric', second: 'numeric'   };
        tabs['Changelog'] = <div className="row">
            <table className="table table-hover">
                <tbody>
            {Object.values(sheet.sheet.changelog).reverse().slice(0,100).map((log, index) =>
                <tr key={index}>
                    <td className="text-nowrap">{log.key}</td>
                    <td>{JSON.stringify(log.newValue)}</td>
                    <td className="text-nowrap">{new Date(log.time).toLocaleDateString("nl-NL", dateFormat)}</td>
                </tr>
            )}
                </tbody>
            </table>
        </div>
    }


    let [activeTab, setTab] = useState(0)
    return (
        <SheetContext.Provider value={sheet}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-2">
                        <ButtonInput label="Edit" firebase="edit_mode"/>
                        <ul className="nav nav-tabs">
                            {
                                Object.entries(tabs).map(([name,tab], index) =>
                                    <li className="nav-item" key={index}>
                                        <button className={"nav-link " + (index === activeTab ? "active" : "") } onClick={() => setTab(index)} aria-current={name}>{name}</button>
                                    </li>
                                )
                            }
                        </ul>
                    </div>

                    <div className="col-12">{Object.entries(tabs)[activeTab][1]}</div>

                </div>
            </div>
        </SheetContext.Provider>
    );
}

export default App;
