import {useContext} from "react";
import {SheetContext, getValueOfSheet} from "./SheetContext";
import {modCalculation, showSign} from "./Utils";

export default function BaseStatInput(props) {
    let {sheet, update} = useContext(SheetContext)

    let updateValue = getValueOfSheet(sheet, props.firebase)

    let editMode = getValueOfSheet(sheet, "edit_mode")

    if (editMode) {
        let mod = modCalculation(updateValue)
        return (
            <div>

                <b>{props.label} ({showSign(mod)}): </b>
                <div>
                    <input className="form-control" value={updateValue ?? ""}
                           onChange={(e) => update(props.firebase, e.target.value)}/>
                </div>
            </div>
        );

    } else {
        let mod = modCalculation(updateValue)
        return (
            <div className="mt-1" style={{"border": "1px solid black", "borderRadius": "10px", "textAlign": "center"}}>
                <b>{props.label}</b>
                <div style={{'fontSize': '34px'}}>{showSign(mod)}</div>
                <div>{updateValue}</div>

            </div>
        );
    }


}
