import {useContext} from "react";
import {SheetContext, getValueOfSheet} from "./SheetContext";

export default function ButtonInput(props) {
    let {sheet, update} = useContext(SheetContext)

    let currentValue = getValueOfSheet(sheet, props.firebase)

    return (
        <button  className="btn btn-primary" onClick={() => update(props.firebase, !currentValue)}>Edit</button>
    );

}

