import {createContext} from "react";
import {Jsep} from "jsep";

let sheet = createContext();

export const SheetContext = sheet


export function getNumberValueOfSheet(context, key) {
    let value = getValueOfSheet(context, key) ?? 0

    return parseInt(value) ?? 0
}

export function getValueOfSheet(context, key) {
    function getData(sheet, keys) {
        if (typeof sheet !== 'object') {
            return null;
        }
        if (keys.length > 1) {
            if (sheet[keys[0]]) {
                return getData(sheet[keys[0]], keys.slice(1))
            }
        } else if (keys.length === 1) {
            if (sheet[keys[0]]) {
                return sheet[keys[0]]
            }
        }
        return null
    }

    if (typeof key === 'string') {
        return getData(context, key.split('.'))
    }
    console.log("Could not get value of: ", key)
    return ""
}


function resolveExpression(context, expression) {
    switch (expression['type']) {
        case 'MemberExpression':
            let exprBuilder = (e) => {
                if (e['type'] === 'MemberExpression') {
                    return exprBuilder(e.object) + "." + exprBuilder(e.property)
                } else if (e['type'] === 'Identifier') {
                    return e['name']
                } else {
                    throw new Error("errrr")
                }
            }

            let key = exprBuilder(expression);

            let resolveValueOfSheet = getValueOfSheet(context, key);

            if (Number.isNaN(resolveValueOfSheet)) {
                return 0
            }

            return parseInt(resolveValueOfSheet)
        case 'Identifier':
            let valueOfSheet = getValueOfSheet(context, expression['name']);

            if (Number.isNaN(valueOfSheet)) {
                return 0
            }
            return parseInt(valueOfSheet)
        case 'Literal':
            if (typeof expression.value !== 'number') {
                return 0;
            }

            return expression.value
        case 'CallExpression':
            if (expression['callee']['name'] === 'mod') {
                try {
                    let valueOfSheet = resolveExpression(context, expression['arguments'][0]);
                    return Math.floor((valueOfSheet - 10) / 2)
                } catch (e) {
                    console.error(e)
                    throw e
                }
            } else {
                console.error(expression)
                throw new Error("Unknown function: " + expression['callee']['name'])
            }

        case 'BinaryExpression':
            let left = resolveExpression(context, expression.left)
            let right = resolveExpression(context, expression.right)
            switch (expression['operator']) {
                case "+":
                    return left + right
                default:
                    console.error(expression)
                    throw new Error("Unknown operator: " + expression['operator'])

            }
        default:
            console.error(expression)
            throw new Error("Unknown type: " + expression['type'])

    }

}


export function parseNumberEvaluation(context, calculation) {
    try {
        return resolveExpression(context, Jsep.parse(calculation))
    } catch (e) {
        console.log(`Failed calculation ${calculation}`)
        console.error(e)
        return null
    }
}

