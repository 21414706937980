import {useContext} from "react";
import {SheetContext, getValueOfSheet} from "./SheetContext";
import FirebaseInput from "./FirebaseInput";

export default function Input(props) {
    let {sheet} = useContext(SheetContext)

    let editMode = getValueOfSheet(sheet, "edit_mode")

    let updateValue = getValueOfSheet(sheet, props.firebase)
    if (editMode) {
        return (
            <div>

                <b>{props.label}: </b>
                <div>
                    <FirebaseInput firebase={props.firebase} type={props.type}/>

                </div>
            </div>
        );

    } else {
        return (
            <div>
                <b>{props.label}: </b>
                <span>{updateValue ?? props.fallback ?? ""}</span>

            </div>
        );
    }


}

